import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  User as FirebaseUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { User } from '../types/user';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, userData: Omit<User, 'uid'>) => Promise<void>;
  logout: () => Promise<void>;
  updateUserData: (updates: Partial<User>) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUser(userDoc.data() as User);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const updateUserData = (updates: Partial<User>) => {
    if (user) {
      setUser({ ...user, ...updates });
    }
  };

  const signup = async (email: string, password: string, userData: Omit<User, 'uid'>) => {
    try {
      console.log('Starting signup process...', { email, ...userData });
      
      // Create Firebase user
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Firebase user created:', firebaseUser.uid);
      
      const newUser: User = {
        uid: firebaseUser.uid,
        ...userData,
        bio: "Hello, I'm new to tipd!",
        profile_picture_url: null,
        stripeAccountId: null,
        stripeOnboardingStatus: null,
        stripeEnabled: false,
        
        tipPresets: [2, 5, 10, 20],
        minTipAmount: 1,
        maxTipAmount: 1000,

        displayEmail: false,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        socialLinks: {},
        venmoUsername: null,
        cashappId: null,
        paypalEmail: null,

        payoutSchedule: 'daily',
        defaultCurrency: 'USD',

        financialData: {
          availableBalance: 0,
          pendingBalance: 0,
          lifetimeEarnings: 0,
          lastPayout: null,
          transactions: {
            tips: {},
            payouts: {}
          },
          stats: {
            totalTipsReceived: 0,
            averageTipAmount: 0,
            tipCount: 0,
            lastTipReceived: null
          }
        },

        notificationPreferences: {
          email: true,
          push: true,
          tipReceived: true,
          accountUpdates: true
        },
        privacySettings: {
          profileVisibility: 'public',
          showTipAmount: true,
          showBusinessInfo: true
        },
        marketingConsent: false,

        accountStatus: 'active',
        loginCount: 1,
        verificationStatus: {
          email: false
        },
        
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        lastLoginAt: new Date().toISOString()
      };

      console.log('Attempting to create user document in Firestore...', { 
        uid: firebaseUser.uid,
        path: `users/${firebaseUser.uid}`,
        auth: !!auth.currentUser,
        authUID: auth.currentUser?.uid,
        authEmail: auth.currentUser?.email,
        data: newUser,
        requiredFields: [
          'username', 'firstName', 'lastName', 'email', 'accountType',
          'tipPresets', 'minTipAmount', 'maxTipAmount', 'displayEmail',
          'timezone', 'socialLinks', 'payoutSchedule', 'defaultCurrency',
          'financialData', 'notificationPreferences', 'privacySettings',
          'marketingConsent', 'accountStatus', 'loginCount', 'verificationStatus'
        ],
        hasFields: Object.keys(newUser)
      });
      
      try {
        // Create user document in Firestore
        const userRef = doc(db, 'users', firebaseUser.uid);
        await setDoc(userRef, newUser);
        console.log('User document created successfully');
      } catch (firestoreError) {
        console.error('Firestore document creation error:', firestoreError);
        console.error('Error details:', {
          code: firestoreError.code,
          message: firestoreError.message,
          details: firestoreError.details,
          name: firestoreError.name
        });
        // Attempt to delete the Firebase auth user since Firestore creation failed
        await firebaseUser.delete();
        throw firestoreError;
      }
      
      setUser(newUser);
      console.log('Signup process completed successfully');
      
      return newUser;
    } catch (error) {
      console.error('Signup error:', error);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      throw new Error(error instanceof Error ? error.message : 'Failed to create account');
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data() as User;
        setUser(userData);
        return userData;
      } else {
        throw new Error('User data not found');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to log in');
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to log out');
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, signup, logout, updateUserData }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
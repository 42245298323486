import React from 'react';

function Logo() {
  return (
    <div className="flex items-center justify-center gap-1 hover:opacity-80 transition-opacity">
      <img src="/tipdsquare.png" alt="Tipd Logo" className="w-5 h-5" />
      <span className="text-2xl font-bold bg-gradient-to-r from-navy-600 to-navy-800 bg-clip-text text-transparent">
        tipd
      </span>
    </div>
  );
}

export default Logo;
import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Comment } from '../types/comments';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare, Trash2, Check, X, ChevronDown } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

interface CommentListProps {
  recipientId: string;  // This will be the username
  className?: string;
}

function CommentList({ recipientId, className = '' }: CommentListProps) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useAuth();
  const [currentUserUsername, setCurrentUserUsername] = useState<string | null>(null);

  useEffect(() => {
    const fetchComments = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // First, get the user's ID from their username
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('username', '==', recipientId));
        const userSnapshot = await getDocs(userQuery);
        
        if (userSnapshot.empty) {
          throw new Error('User not found');
        }

        const userId = userSnapshot.docs[0].id;
        
        // Then fetch their comments
        const commentsRef = collection(db, `users/${userId}/comments`);
        const commentsQuery = query(commentsRef, orderBy('timestamp', 'desc'));
        const commentsSnapshot = await getDocs(commentsQuery);
        
        const fetchedComments = commentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Comment[];

        setComments(fetchedComments);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load comments');
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();
  }, [recipientId]);

  useEffect(() => {
    const fetchUserUsername = async () => {
      if (user) {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setCurrentUserUsername(querySnapshot.docs[0].data().username);
        }
      }
    };
    fetchUserUsername();
  }, [user]);

  const handleDeleteComment = async (commentId: string) => {
    try {
      const usersRef = collection(db, 'users');
      const userQuery = query(usersRef, where('username', '==', recipientId));
      const userSnapshot = await getDocs(userQuery);
      
      if (userSnapshot.empty) {
        throw new Error('User not found');
      }

      const userId = userSnapshot.docs[0].id;
      await deleteDoc(doc(db, `users/${userId}/comments/${commentId}`));
      
      // Update local state
      setComments(comments.filter(comment => comment.id !== commentId));
      setDeleteModalOpen(false);
      setCommentToDelete(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete comment');
    }
  };

  const handleApproval = async (commentId: string, approved: boolean) => {
    try {
      const usersRef = collection(db, 'users');
      const userQuery = query(usersRef, where('username', '==', recipientId));
      const userSnapshot = await getDocs(userQuery);
      
      if (userSnapshot.empty) {
        throw new Error('User not found');
      }

      const userId = userSnapshot.docs[0].id;
      await updateDoc(doc(db, `users/${userId}/comments/${commentId}`), {
        approved
      });
      
      // Update local state
      setComments(comments.map(comment => 
        comment.id === commentId ? { ...comment, approved } : comment
      ));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update comment');
    }
  };

  const openDeleteModal = (commentId: string) => {
    setCommentToDelete(commentId);
    setDeleteModalOpen(true);
  };

  // Filter comments based on user role and approval status
  const filteredComments = comments.filter(comment => {
    if (user && currentUserUsername === recipientId) {
      // Profile owner sees all comments
      return true;
    } else {
      // Other users only see approved comments
      return comment.approved === true;
    }
  });

  if (isLoading) {
    return (
      <div className={`mt-8 bg-white rounded-lg shadow-lg p-6 ${className}`}>
        <div className="flex items-center justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-navy-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`mt-8 bg-white rounded-lg shadow-lg p-6 ${className}`}>
        <div className="text-center text-red-600 py-4">
          {error}
        </div>
      </div>
    );
  }

  if (filteredComments.length === 0) {
    return (
      <div className={`mt-8 bg-white rounded-lg shadow-lg p-6 ${className}`}>
        <div className="text-center py-8 text-gray-500">
          <MessageSquare className="w-12 h-12 mx-auto mb-4 opacity-50" />
          <p>No comments yet</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`mt-8 mb-12 bg-white rounded-lg shadow-lg ${className}`}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full p-6 flex items-center justify-between hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center gap-2">
          <MessageSquare className="w-5 h-5 text-gray-600" />
          <h2 className="text-xl font-semibold">
            Comments ({filteredComments.length})
          </h2>
        </div>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-5 h-5 text-gray-600" />
        </motion.div>
      </button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-6 pb-6 space-y-6">
              <AnimatePresence mode="popLayout">
                {filteredComments.map((comment) => (
                  <motion.div
                    key={comment.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                    className="border-b border-gray-100 last:border-0 pb-6 last:pb-0 relative"
                  >
                    <div className="text-gray-800 pr-8">
                      <div className="flex items-center gap-2 mb-1">
                        {comment.name && (
                          <div className="text-base font-bold text-gray-600">
                            {comment.name}
                          </div>
                        )}
                        {comment.approved === false && (
                          <span className="px-2 py-0.5 text-xs font-medium bg-red-100 text-red-600 rounded-full">
                            Hidden
                          </span>
                        )}
                      </div>
                      <div className="text-gray-700">
                        {comment.text}
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-4">
                      <div className="text-[8px] text-gray-500">
                        {new Date(comment.timestamp).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </div>
                      {user && currentUserUsername === recipientId && (
                        <div className="flex space-x-2">
                          {comment.approved === null ? (
                            <>
                              <button
                                onClick={() => handleApproval(comment.id, true)}
                                className="p-1.5 rounded-full bg-green-100 text-green-600 hover:bg-green-200 hover:text-green-700 transition-colors"
                                aria-label="Approve comment"
                              >
                                <Check className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => handleApproval(comment.id, false)}
                                className="p-1.5 rounded-full bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-700 transition-colors"
                                aria-label="Deny comment"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </>
                          ) : null}
                          <button
                            onClick={() => openDeleteModal(comment.id)}
                            className="p-1.5 rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-700 transition-colors"
                            aria-label="Delete comment"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Delete Comment</h3>
            <p className="text-gray-600 mb-6">Are you sure you want to delete this comment? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => commentToDelete && handleDeleteComment(commentToDelete)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CommentList;

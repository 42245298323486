import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';
import { ArrowLeft, ArrowRight, Upload, Check } from 'lucide-react';

interface OnboardingFormData {
  // Personal Information
  firstName: string;
  lastName: string;
  dateOfBirth: {
    month: string;
    day: string;
    year: string;
  };
  email: string;
  phone: string;
  ssn: string;

  // Address
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };

  // Business Information
  businessType: 'individual' | 'company';
  businessName?: string;
  ein?: string;
  
  // Bank Account
  accountNumber: string;
  routingNumber: string;
  
  // Documents
  identityDocument?: File;
  addressDocument?: File;
}

const OnboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<OnboardingFormData>({
    firstName: '',
    lastName: '',
    dateOfBirth: {
      month: '',
      day: '',
      year: '',
    },
    email: user?.email || '',
    phone: '',
    ssn: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    businessType: 'individual',
    accountNumber: '',
    routingNumber: '',
  });

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent as keyof OnboardingFormData],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'identity' | 'address') => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      // Create a FormData instance
      const formData = new FormData();
      formData.append('file', file);
      formData.append('purpose', type === 'identity' ? 'identity_document' : 'document');

      // Upload to Stripe through your backend
      const response = await fetch('/.netlify/functions/upload-document', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Document upload failed');

      setFormData(prev => ({
        ...prev,
        [type === 'identity' ? 'identityDocument' : 'addressDocument']: file
      }));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload document');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Create Custom Account
      const accountResponse = await fetch('/.netlify/functions/create-custom-account', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user?.uid,
          formData
        }),
      });

      if (!accountResponse.ok) {
        throw new Error('Failed to create Stripe account');
      }

      const { accountId } = await accountResponse.json();

      // Update account with bank account details
      const bankAccountResponse = await fetch('/.netlify/functions/add-bank-account', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId,
          accountNumber: formData.accountNumber,
          routingNumber: formData.routingNumber,
        }),
      });

      if (!bankAccountResponse.ok) {
        throw new Error('Failed to add bank account');
      }

      // Navigate to success page
      navigate('/onboarding-success');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred during onboarding');
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-4"
          >
            <h2 className="text-xl font-semibold">Personal Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />
              <div className="col-span-2 grid grid-cols-3 gap-4">
                <select
                  name="dateOfBirth.month"
                  value={formData.dateOfBirth.month}
                  onChange={handleInputChange}
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                >
                  <option value="">Month</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const month = i + 1;
                    return (
                      <option key={month} value={month.toString().padStart(2, '0')}>
                        {new Date(2000, i).toLocaleString('default', { month: 'long' })}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="dateOfBirth.day"
                  value={formData.dateOfBirth.day}
                  onChange={handleInputChange}
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                >
                  <option value="">Day</option>
                  {Array.from({ length: 31 }, (_, i) => {
                    const day = i + 1;
                    return (
                      <option key={day} value={day.toString().padStart(2, '0')}>
                        {day}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="dateOfBirth.year"
                  value={formData.dateOfBirth.year}
                  onChange={handleInputChange}
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                >
                  <option value="">Year</option>
                  {Array.from({ length: 100 }, (_, i) => {
                    const year = new Date().getFullYear() - i - 18; // Start from 18 years ago
                    return (
                      <option key={year} value={year.toString()}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                className="input-field col-span-2 border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />

            </div>
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-4"
          >
            <h2 className="text-xl font-semibold">Social Security Number</h2>
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-sm text-gray-600">For verification purposes only</p>
                <input
                  type="text"
                  name="ssn"
                  value={formData.ssn}
                  onChange={handleInputChange}
                  placeholder="Last 4 of SSN"
                  maxLength={4}
                  className="input-field w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                  pattern="[0-9]{4}"
                />
              </div>
            </div>
          </motion.div>
        );

      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-4"
          >
            <h2 className="text-xl font-semibold">Address Information</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="address.line1"
                value={formData.address.line1}
                onChange={handleInputChange}
                placeholder="Street Address"
                className="input-field w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />
              <input
                type="text"
                name="address.line2"
                value={formData.address.line2}
                onChange={handleInputChange}
                placeholder="Apt, Suite, etc. (optional)"
                className="input-field w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
              />
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="address.city"
                  value={formData.address.city}
                  onChange={handleInputChange}
                  placeholder="City"
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                />
                <input
                  type="text"
                  name="address.state"
                  value={formData.address.state}
                  onChange={handleInputChange}
                  placeholder="State"
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                />
                <input
                  type="text"
                  name="address.postalCode"
                  value={formData.address.postalCode}
                  onChange={handleInputChange}
                  placeholder="ZIP Code"
                  className="input-field border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                  required
                />
              </div>
            </div>
          </motion.div>
        );

      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-4"
          >
            <h2 className="text-xl font-semibold">Document Verification</h2>
            <div className="space-y-6">
              <div className="border rounded-lg p-4">
                <h3 className="font-medium mb-2">Identity Document</h3>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  onChange={(e) => handleFileUpload(e, 'identity')}
                  className="hidden"
                  id="identity-document"
                  required
                />
                <label
                  htmlFor="identity-document"
                  className="flex items-center justify-center border-2 border-dashed rounded-lg p-4 cursor-pointer hover:border-navy-600"
                >
                  {formData.identityDocument ? (
                    <div className="flex items-center text-green-600">
                      <Check className="w-5 h-5 mr-2" />
                      Document uploaded
                    </div>
                  ) : (
                    <div className="flex items-center text-gray-600">
                      <Upload className="w-5 h-5 mr-2" />
                      Upload ID (Driver's License or Passport)
                    </div>
                  )}
                </label>
              </div>

              <div className="border rounded-lg p-4">
                <h3 className="font-medium mb-2">Proof of Address</h3>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  onChange={(e) => handleFileUpload(e, 'address')}
                  className="hidden"
                  id="address-document"
                  required
                />
                <label
                  htmlFor="address-document"
                  className="flex items-center justify-center border-2 border-dashed rounded-lg p-4 cursor-pointer hover:border-navy-600"
                >
                  {formData.addressDocument ? (
                    <div className="flex items-center text-green-600">
                      <Check className="w-5 h-5 mr-2" />
                      Document uploaded
                    </div>
                  ) : (
                    <div className="flex items-center text-gray-600">
                      <Upload className="w-5 h-5 mr-2" />
                      Upload proof of address
                    </div>
                  )}
                </label>
              </div>
            </div>
          </motion.div>
        );

      case 5:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-4"
          >
            <h2 className="text-xl font-semibold">Banking Information</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleInputChange}
                placeholder="Account Number"
                className="input-field w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />
              <input
                type="text"
                name="routingNumber"
                value={formData.routingNumber}
                onChange={handleInputChange}
                placeholder="Routing Number"
                className="input-field w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-navy-600 focus:ring-1 focus:ring-navy-600 outline-none"
                required
              />
              <div className="mt-4">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </motion.div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-900">Set Up Your Account</h1>
            <p className="text-gray-600 mt-2">Complete your profile to start receiving payments</p>
          </div>

          <div className="mb-8">
            <div className="flex justify-between items-center">
              {[1, 2, 3, 4].map((stepNumber) => (
                <div
                  key={stepNumber}
                  className={`w-1/4 h-2 rounded-full ${
                    stepNumber <= step ? 'bg-navy-600' : 'bg-gray-200'
                  }`}
                />
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {renderStep()}

            {error && (
              <div className="bg-red-50 text-red-600 p-4 rounded-lg">
                {error}
              </div>
            )}

            <div className="flex justify-between mt-8">
              {step > 1 && (
                <button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className="flex items-center text-gray-600 hover:text-gray-900"
                >
                  <ArrowLeft className="w-4 h-4 mr-2" />
                  Previous
                </button>
              )}

              {step < 4 ? (
                <button
                  type="button"
                  onClick={() => setStep(step + 1)}
                  className="flex items-center bg-navy-600 text-white px-6 py-2 rounded-lg hover:bg-navy-700"
                >
                  Next
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={loading}
                  className="flex items-center bg-navy-600 text-white px-6 py-2 rounded-lg hover:bg-navy-700 disabled:opacity-50"
                >
                  {loading ? 'Processing...' : 'Complete Setup'}
                  {!loading && <Check className="w-4 h-4 ml-2" />}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardPage;
import React from 'react';
import Footer from '../../components/Footer';
import { ShieldAlert, Mail } from 'lucide-react';

function DisputePage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <div className="text-center mb-8">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Refunds & Disputes</h1>
            <ShieldAlert className="w-12 h-12 text-navy-600 mx-auto mb-4" />
          </div>
          
          <div className="space-y-6 text-gray-600">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-3">Our Policy</h2>
              <p>
                At TIPD, we take disputes seriously and aim to resolve any issues fairly and promptly. 
                We process refunds in accordance with our terms of service and applicable laws.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-3">Refund Eligibility</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>Unauthorized transactions</li>
                <li>Technical errors resulting in incorrect charges</li>
                <li>Services not received as described</li>
                <li>Duplicate charges</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-3">Filing a Dispute</h2>
              <p className="mb-4">
                To file a dispute or request a refund, please contact our support team with the following information:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Transaction ID or date</li>
                <li>Amount in question</li>
                <li>Reason for dispute</li>
                <li>Any relevant screenshots or documentation</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-3">Processing Time</h2>
              <p>
                We aim to review and respond to all disputes within 2-3 business days. 
                If approved, refunds typically process within 5-10 business days, depending on your payment method and financial institution.
              </p>
            </section>

            <div className="mt-8 p-4 bg-navy-50 rounded-lg">
              <h2 className="text-xl font-semibold text-gray-900 mb-2">Need Help?</h2>
              <div className="flex items-center justify-center gap-2">
                <Mail className="w-5 h-5 text-navy-600" />
                <a 
                  href="mailto:support@tipd.xyz" 
                  className="text-navy-600 hover:text-navy-700 transition-colors"
                >
                  support@tipd.xyz
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default DisputePage;

import React from 'react';
import { Link } from 'react-router-dom';

interface FooterProps {
  className?: string;
}

function Footer({ className = '' }: FooterProps) {
  const links = [
    { text: 'About', href: '/about' },
    { text: 'Terms', href: '/terms' },
    { text: 'Privacy', href: '/privacy' },
    { text: 'Help', href: '/help' },
    { text: 'Contact', href: '/contact' },
    { text: 'Disputes', href: '/disputes' },
  ];

  return (
    <footer className={`mt-8 pb-4 ${className}`}>
      <div className="flex flex-wrap justify-center gap-x-6 gap-y-2">
        {links.map((link) => (
          <Link
            key={link.href}
            to={link.href}
            className="text-sm text-gray-400 hover:text-gray-600 transition-colors"
          >
            {link.text}
          </Link>
        ))}
      </div>
      <div className="text-center mt-12 text-xs text-gray-400">
        &copy; TIPD, LLC 2024
      </div>
    </footer>
  );
}

export default Footer;
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Settings, Camera } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { v4 as uuidv4 } from 'uuid';
import TipInput from './TipInput';
import PaymentButton from './PaymentButton';
import Footer from './Footer';
import TipJar from './TipJar';
import OtherPayments from './OtherPayments';
import Comments from './Comments';
import CommentList from './CommentList';
import NFC from './NFC';

function ProfilePage() {
  const navigate = useNavigate();
  const { username } = useParams();
  const { user, logout } = useAuth();
  const [tipAmount, setTipAmount] = useState<number | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null);
  const [editedBio, setEditedBio] = useState(userData?.bio || '');
  const [editedFirstName, setEditedFirstName] = useState(userData?.firstName || '');
  const [editedLastName, setEditedLastName] = useState(userData?.lastName || '');
  const [tipPresets, setTipPresets] = useState(userData?.tipPresets || [2, 5, 10, 20]);
  const [editedVenmo, setEditedVenmo] = useState(userData?.venmoUsername || '');
  const [editedCashapp, setEditedCashapp] = useState(userData?.cashappId || '');
  const [editedPaypal, setEditedPaypal] = useState(userData?.paypalEmail || '');
  const [isOtherPaymentsExpanded, setIsOtherPaymentsExpanded] = useState(false);
  const [showNFCWriter, setShowNFCWriter] = useState(false);

  const isOwnProfile = user?.username === username;

  useEffect(() => {
    const fetchUser = async () => {
      if (!username) {
        setError('No username provided');
        setIsLoading(false);
        return;
      }

      try {
        const usersRef = collection(db, 'users');
        let q = query(usersRef, where('username', '==', username));
        let querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          q = query(usersRef, where('username', '==', username.toLowerCase()));
          querySnapshot = await getDocs(q);
        }

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const fetchedUserData = userDoc.data();
          setUserData(fetchedUserData);
          setEditedBio(fetchedUserData.bio || '');
          setEditedFirstName(fetchedUserData.firstName || '');
          setEditedLastName(fetchedUserData.lastName || '');
          setTipPresets(fetchedUserData.tipPresets || [2, 5, 10, 20]);
          setEditedVenmo(fetchedUserData.venmoUsername || '');
          setEditedCashapp(fetchedUserData.cashappId || '');
          setEditedPaypal(fetchedUserData.paypalEmail || '');
        } else {
          setError(`User @${username} not found`);
          setUserData(null);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to load user profile');
        setUserData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [username]);

  useEffect(() => {
    const fetchLoginLink = async () => {
      if (userData?.stripeAccountId) {
        try {
          const response = await fetch('/.netlify/functions/stripe-login-link', {
            method: 'POST',
            body: JSON.stringify({ stripeAccountId: userData.stripeAccountId })
          });
          
          if (!response.ok) throw new Error('Failed to get login link');
          
          const data = await response.json();
          setDashboardUrl(data.url);
        } catch (error) {
          console.error('Error fetching login link:', error);
        }
      }
    };

    fetchLoginLink();
  }, [userData?.stripeAccountId]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    setIsUploading(true);
    try {
      const fileId = uuidv4();
      const fileRef = ref(storage, `profile-pictures/${user.uid}/${fileId}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        profile_picture_url: url,
        updatedAt: new Date().toISOString()
      });

      setEditedBio(userData.bio || '');
      setEditedFirstName(userData.firstName || '');
      setEditedLastName(userData.lastName || '');
      setTipPresets(userData.tipPresets || [2, 5, 10, 20]);
      setEditedVenmo(userData.venmoUsername || '');
      setEditedCashapp(userData.cashappId || '');
      setEditedPaypal(userData.paypalEmail || '');
      setUserData(prev => ({ ...prev, profile_picture_url: url }));
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload profile picture');
    } finally {
      setIsUploading(false);
    }
  };

  const handleTipPresetChange = (index: number, value: string) => {
    const newPresets = [...tipPresets];
    newPresets[index] = value === '' ? 0 : parseFloat(value);
    setTipPresets(newPresets);
  };

  const handleSaveChanges = async () => {
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        bio: editedBio,
        firstName: editedFirstName,
        lastName: editedLastName,
        tipPresets: tipPresets,
        venmoUsername: editedVenmo || null,
        cashappId: editedCashapp || null,
        paypalEmail: editedPaypal || null,
        updatedAt: new Date().toISOString()
      });

      setUserData(prevData => ({
        ...prevData!,
        bio: editedBio,
        firstName: editedFirstName,
        lastName: editedLastName,
        tipPresets: tipPresets,
        venmoUsername: editedVenmo || null,
        cashappId: editedCashapp || null,
        paypalEmail: editedPaypal || null
      }));

      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to log out');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-navy-600 border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white p-4">
        <div className="max-w-2xl mx-auto pt-8 text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">{error}</h1>
          <Link to="/" className="text-navy-600 hover:text-navy-700">
            Return to Home
          </Link>
        </div>
      </div>
    );
  }

  if (!userData) return null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-2xl mx-auto px-4 py-4">
        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          {isOwnProfile && (
            <div className="flex justify-end mb-4">
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="p-2 text-gray-600 hover:text-gray-900 transition-colors"
              >
                <Settings className="w-5 h-5" />
              </button>
            </div>
          )}

          <div className="relative mb-8">
            <div className="relative w-24 h-24 mx-auto mb-4">
              {userData.profile_picture_url ? (
                <img
                  src={userData.profile_picture_url}
                  alt={userData.firstName + ' ' + userData.lastName}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <div className="w-full h-full rounded-full bg-navy-600 flex items-center justify-center">
                  <User className="w-12 h-12 text-white" />
                </div>
              )}
              {isOwnProfile && (
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    accept="image/*"
                    className="hidden"
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50"
                  >
                    <Camera className="w-4 h-4 text-gray-600" />
                  </button>
                </>
              )}
            </div>

            {isEditing ? (
              <div className="space-y-4">
                <div className="flex justify-end gap-4 mt-6">
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 text-gray-600 hover:text-red-900"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveChanges}
                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                  >
                    Save Changes
                  </button>
                </div>
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={editedFirstName}
                    onChange={(e) => setEditedFirstName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-navy-500 focus:border-navy-500"
                  />
                </div>
                
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={editedLastName}
                    onChange={(e) => setEditedLastName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-navy-500 focus:border-navy-500"
                  />
                </div>

                <div>
                  <label htmlFor="bio" className="block text-sm font-medium text-gray-700 mb-1">
                    Bio
                  </label>
                  <textarea
                    id="bio"
                    value={editedBio}
                    onChange={(e) => setEditedBio(e.target.value)}
                    rows={3}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-navy-500 focus:border-navy-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tip Presets
                  </label>
                  <div className="grid grid-cols-4 gap-2 mb-8">
                    {tipPresets.map((preset, index) => (
                      <div key={index} className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                        <input
                          type="number"
                          value={preset || ''}
                          onChange={(e) => handleTipPresetChange(index, e.target.value)}
                          className="w-full pl-8 pr-2 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-navy-500 focus:border-transparent"
                          min="0"
                          step="1"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-4">
                    Alternative Payment Methods
                  </label>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="venmo" className="block text-sm text-gray-600 mb-1">
                        Venmo Username
                      </label>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">@</span>
                        <input
                          type="text"
                          id="venmo"
                          value={editedVenmo}
                          onChange={(e) => setEditedVenmo(e.target.value)}
                          className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#3D95CE] focus:border-transparent"
                          placeholder="username"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="cashapp" className="block text-sm text-gray-600 mb-1">
                        Cash App ID
                      </label>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                        <input
                          type="text"
                          id="cashapp"
                          value={editedCashapp}
                          onChange={(e) => setEditedCashapp(e.target.value)}
                          className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#00D632] focus:border-transparent"
                          placeholder="cashtag"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="paypal" className="block text-sm text-gray-600 mb-1">
                        PayPal Username
                      </label>
                      <input
                        type="text"
                        id="paypal"
                        value={editedPaypal}
                        onChange={(e) => setEditedPaypal(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#003087] focus:border-transparent"
                        placeholder="email or username"
                      />
                    </div>

                    <div className="flex justify-end gap-4 mt-6">
                      <button
                        onClick={() => setIsEditing(false)}
                        className="px-4 py-2 text-gray-600 hover:text-red-900"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveChanges}
                        className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                
              </div>
            ) : (
              <>
                <div className="text-center">
                  <h1 className="text-2xl font-bold text-gray-900">
                    {userData.firstName} {userData.lastName}
                  </h1>
                  <p className="text-gray-500 mt-1">@{userData.username}</p>
                </div>

                {userData.bio && (
                  <p className="text-gray-600 text-center max-w-md mx-auto mt-4">
                    {userData.bio}
                  </p>
                )}
              </>
            )}
          </div>

          {isOwnProfile ? (
            <>
              {userData.stripeEnabled ? (
                <>
                  {dashboardUrl && (
                    <a
                      href={dashboardUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full px-6 py-3 mb-8 text-center bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
                    >
                      Payments Dashboard
                    </a>
                  )}
                </>
              ) : (
                <div className="text-center">
                  <p className="text-gray-600 mb-4">
                    Set up your payment account to start receiving tips
                  </p>
                  <Link
                    to="/payment-setup"
                    className="inline-block px-6 py-3 bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
                  >
                    Set Up Payments
                  </Link>
                </div>
              )}
              <div className="flex flex-col items-center gap-4 mt-4">
                <button
                  onClick={handleLogout}
                  className="w-full text-center text-gray-500 hover:text-gray-700"
                >
                  Log out
                </button>
                <button
                  onClick={() => setShowNFCWriter(!showNFCWriter)}
                  className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-300 hover:bg-gray-50 transition-colors"
                >
                  {showNFCWriter ? 'Hide NFC Writer' : 'Write NFC Tag'}
                </button>

                 {/* <AnimatePresence>
                  {showNFCWriter && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 mt-4"
                    >
                   } <NFC
                        onSuccess={() => {
                          setShowNFCWriter(false);
                          // You can add a success toast here if you want
                        }}
                        onError={(error) => {
                          console.error('NFC error:', error);
                          // You can add an error toast here if you want
                        }}
                      /> 
                    </motion.div>
                  )}
                </AnimatePresence> */}
              </div>
            </>
          ) : (
            <>
              {!isOwnProfile && userData.stripeEnabled && (
                <div className="mt-8">
                  <TipInput
                    value={tipAmount}
                    onChange={setTipAmount}
                    presets={userData.tipPresets}
                  />
                  {userData.stripeAccountId ? (
                    <>
                      <div className="mb-4">
                        <PaymentButton
                          amount={tipAmount}
                          stripeAccountId={userData.stripeAccountId}
                          recipientName={`${userData.firstName} ${userData.lastName}`}
                          recipientUsername={userData.username}
                          onOtherPaymentsClick={() => setIsOtherPaymentsExpanded(true)}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-gray-600 mt-4">
                      This user hasn't set up their payment account yet
                    </p>
                  )}
                </div>
              )}
              <div>
                <OtherPayments
                  venmoUsername={userData.venmoUsername}
                  cashappId={userData.cashappId}
                  paypalEmail={userData.paypalEmail}
                  tipAmount={tipAmount}
                  isExpanded={isOtherPaymentsExpanded}
                  onExpandedChange={setIsOtherPaymentsExpanded}
                />
              </div>
            </>
          )}
        </div>

        {username && (
          <div>
           {/* <Comments recipientId={username} onCommentSubmitted={() => {}} /> */}
            <CommentList recipientId={username} />
          </div>
        )}

      

        <Footer />
      </div>
    </div>
  );
}

export default ProfilePage;
